class MileageMoneySubmitted {
  $routeParams:        any;
  $location:           any;
  api:                 any;
  appFactory:          any;
  loaderFactory:       any;
  errFactory:          any;
  mileageMoneyFactory: any;

  mileageMoney: any;
  constructor ($routeParams, $location, API, AppFactory, LoaderFactory, ErrFactory, MileageMoneyFactory) {
    Object.defineProperties(this, {
      $routeParams:        { value: $routeParams        },
      $location:           { value: $location           },
      api:                 { value: API                 },
      appFactory:          { value: AppFactory          },
      loaderFactory:       { value: LoaderFactory       },
      errFactory:          { value: ErrFactory          },
      mileageMoneyFactory: { value: MileageMoneyFactory }
    });

    let id = parseInt($routeParams.id);
    if (id) this.loadReport(id);
    else $location.path('/');
  }

  private loadReport(id: number): Promise<any> {
    this.loaderFactory.show();
    return this.mileageMoneyFactory.getById(id)
    .then(mm => {
      this.mileageMoney = new this.mileageMoneyFactory.MileageMoneySubmitted(mm);
      this.loaderFactory.hide();
    })
    .catch((err) => {
      if (err instanceof this.errFactory) err.notify();
      else console.error(err);
    });
  }

  totalKm(): number {
    return this.mileageMoney?.workDays?.length ? Math.round((this.mileageMoney?.workDays?.reduce((sum, val) => sum = sum + val.amountOfKm, 0) + Number.EPSILON) * 100) / 100 : 0;
  }

  back(): void {
    this.appFactory.goBack();
  }

}

window.app.component('mileageMoneySubmitted', {
  template: require('scripts/components/mileage-money/mm-submitted/mm-submitted.html'),
  controller: ['$routeParams', '$location', 'API', 'AppFactory', 'LoaderFactory', 'ErrFactory', 'MileageMoneyFactory', MileageMoneySubmitted]
});
