class MileageMoneyConfirm {
  $rootScope:                    any;
  $filter:                       any;
  $location:                     any;
  $translate:                    any;
  api:                           any;
  notification:                  any;
  loaderFactory:                 any;
  errFactory:                    any;
  mileageMoneyFactory:           any;
  timeTrackingValidationService: any;

  prefilledMileageData: any;
  mileageData:          any;
  assignment:           any;
  constructor ($rootScope, $location, $filter, $translate, API, Notification, LoaderFactory, ErrFactory, MileageMoneyFactory, TimeTrackingValidationService) {
    Object.defineProperties(this, {
      $rootScope:                    { value: $rootScope                    },
      $filter:                       { value: $filter                       },
      $location:                     { value: $location                     },
      $translate:                    { value: $translate                    },
      api:                           { value: API                           },
      notification:                  { value: Notification                  },
      loaderFactory:                 { value: LoaderFactory                 },
      errFactory:                    { value: ErrFactory                    },
      mileageMoneyFactory:           { value: MileageMoneyFactory           },
      timeTrackingValidationService: { value: TimeTrackingValidationService }
    });

    if ($rootScope.mileageData) this.prepareMileage($rootScope.mileageData);
    else $location.path(`/`);
  }

  private prepareMileage(mileageData): void {
    this.prefilledMileageData = new this.mileageMoneyFactory(mileageData);
    this.mileageData          = new this.mileageMoneyFactory(mileageData);
    this.assignment           = mileageData.assignment;
    this.createFirstDay();
  }

  validateDistance(workDay): void {
    if (!workDay.amountOfKm) workDay.amountOfKm = 1;
    this.validate();
  }

  createFirstDay(): void {
    this.mileageData.workDays = [];
    this.mileageData.workDays.push(new this.mileageMoneyFactory.MileageMoneyWorkDay(this.prefilledMileageData.workDays[0].toJSON()));
  }

  copy(wd): void {
    let validDays = this.prefilledMileageData.workDays.filter(pwd => !this.mileageData.workDays.find(wd => wd.date.getTime() === pwd.date.getTime()));
    if (validDays?.length) {
      let day  = wd.toJSON();
      day.date = this.$filter('date')(validDays[0].date, 'yyyy-MM-dd');
      this.mileageData.workDays.push(new this.mileageMoneyFactory.MileageMoneyWorkDay(day));
      this.validate();
    } else this.notification.alert({
      title: 'note',
      desc:  'mileageMoney.noValidDaysForMileageMoney'
    });
  }

  delete(index: number): void {
    this.mileageData.workDays.splice(index, 1);
    this.validate();
  }

  totalKm(): number {
    return Math.round((this.mileageData?.workDays?.reduce((sum, val) => sum = sum + val.amountOfKm, 0) + Number.EPSILON) * 100) / 100;
  }

  submit(): Promise<any> {
    let errors = [...new Set(this.mileageData.workDays.reduce((sum, val) => {
      sum = [...sum, ...(val?.errorsList || [])];
      return sum;
    }, []))];
    if (errors?.length) return this.$translate(errors).then(t => this.notification.alert({
      title: 'errors.checkHighlightedFields',
      desc:  Object.values(t).map(e => `- ${e}`).join('\n')
    }));

    this.loaderFactory.show();
    let data = {
      start_date:       this.$filter('date')(this.mileageData.startDate, 'yyyy-MM-dd'),
      end_date:         this.$filter('date')(this.mileageData.endDate,   'yyyy-MM-dd'),
      assignment_id:    this.assignment.id,
      ebs_data_id:      this.mileageData.ebsDataId,
      license_plate_id: this.mileageData.licensePlateId,
      work_days:        this.mileageData.workDays.map(wd => ({
        date:                      this.$filter('date')(wd.date, 'yyyy-MM-dd'),
        amount_of_km:              wd.amountOfKm,
        assignment_address:        wd.assignmentAddress.trim(),
        external_employee_address: wd.externalEmployeeAddress.trim()
      }))
    };

    return this.api.submitMileageMoney(data)
    .then(() => {
      this.$rootScope.mileageData = null;
      return this.notification.alert({
        title: 'activityReport.successfulTitle',
        desc:  'mileageMoney.successfulMessage'
      }, this.$location.path('/'));
    })
    .catch((err) => {
      if (err instanceof this.errFactory) err.notify();
      else console.error(err);
    })
    .then(() => this.loaderFactory.hide());
  }

  back(): void {
    this.$location.path(`/`);
  }

  validate(): void {
    this.mileageData.workDays.forEach(wd => {
      wd.errors     = [];
      wd.errorsList = [];
    });
    this.timeTrackingValidationService.validateMileageReport(this.mileageData.workDays, this.prefilledMileageData.workDays);
    this.mileageData.workDays.forEach(wd => wd.errorsList = [...new Set(wd.errors.map(e => e.message))]);
  }

  dayError(workDay) {
    return workDay?.errors?.find(e => e.dayError);
  }

}

window.app.component('mileageMoneyConfirm', {
  template: require('scripts/components/mileage-money/mm-confirm/mm-confirm.html'),
  controller: ['$rootScope', '$location', '$filter', '$translate', 'API', 'Notification', 'LoaderFactory', 'ErrFactory', 'MileageMoneyFactory', 'TimeTrackingValidationService', MileageMoneyConfirm]
});
