class NestedListItem {
  $location: any;
  title:        string;
  subtitle:     string;
  icon:         string;
  url:          string;
  warning:      string;
  counter:      string;
  notification: string;
  arrow:        string;
  constructor ($location: any) {
    Object.defineProperty(this, '$location', { value: $location });
  }

  redirect(e) {
    if (this.url) {
      e.stopPropagation();
      return this.$location.path(this.url);
    }
  };
}
  
window.app.component('nestedListItem', {
  template: require('scripts/components/nested-list-item/nested-list-item.html'),
  controller: ['$location', NestedListItem],
  bindings: {
    title:        '=',
    subtitle:     '=',
    icon:         '=',
    url:          '=',
    warning:      '=',
    disabled:     '=',
    counter:      '=',
    notification: '=',
    arrow:        '='
  }
});
