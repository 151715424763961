class Kilometergeld {
  constructor($location, $scope, $http, Notification, AuthService, ErrFactory, AppFactory, API, LoaderFactory) {
    Object.defineProperties(this, {
      $location: { value: $location },
      $scope: { value: $scope },
      $http: { value: $http },
      notification: { value: Notification },
      errFactory: { value: ErrFactory },
      appFactory: { value: AppFactory },
      authService: { value: AuthService },
      loaderFactory: { value: LoaderFactory },
      api: { value: API }
    });
    this.loading = false;
		this.loaderFactory.show();
    Promise.resolve(AuthService.loadUser())
    .then(user => {
      this.user = user;
      this.licensePlate = user.license_plate || null;
      this.licensePlateCountry = user.license_plate_country || 'DE';
      if (user.license_plate_signature_url) {
        if (user.signatureBase64) this.signature = user.signatureBase64;
        else return this.loadSignature(user.license_plate_signature_url).then(image => {
          this.user.signatureBase64 = image;
          this.signature = image;
          return this.user.saveLocal();
        });
      }
    })
    .then(() => this.$scope.$apply())
    .catch((e) => {
      if (e instanceof ErrFactory) e.notify();
      else console.error(e);
    })
    .then(() => this.loaderFactory.hide());
  }

  loadSignature(url) {
    return Promise.resolve()
    .then(() => this.$http({
      url: url,
      method: 'GET',
      responseType: 'blob'
    }))
    .then(res => this.blobToBase64(res.data));
  }

  blobToBase64(blob) {
    return new Promise((res, rej) => {
      var reader = new FileReader();
      reader.onload = () => {
        let base64 = reader.result;
        res(base64);
      }
      reader.readAsDataURL(blob);
    });
  }

  goBack(event) {
    event.preventDefault();
    event.stopPropagation();
    this.$location.path('/');
  }

  changesDetected() {
    if (this.user.license_plate         !== this.licensePlate)        return true;
    if (this.user.license_plate_country !== this.licensePlateCountry) return true;
    if (this.user.signatureBase64       !== this.signature)           return true;
    return false;
  }

  submitForm() {
    this.loading = true;
		this.loaderFactory.show();
    let params = {
      user_license_plate: {
        license_plate: this.licensePlate.trim(),
        country: this.licensePlateCountry,
        signature: this.signature
      }
    };
    Promise.resolve(this.api.updateLicensePlate(params))
    .then(() => {
      this.user.signatureBase64 = this.signature;
      return this.user.saveLocal();
    })
    .then(res => this.authService.getAuthorizedUser().update())
    .then(res => this.loaderFactory.hide())
    .then(res => this.notification.alert({
      title: 'successfully',
      desc: 'dataSavedSuccessfully'
    }, () => {
      this.appFactory.goBack();
    }))
    .catch((e) => {
      this.loaderFactory.hide();
      if (e instanceof this.errFactory) e.notify(this.loading = false);
      else {
        console.error(e);
        this.loading = false;
      }
    });
  }

}

app.component('kilometergeld', {
  template: require('scripts/components/kilometergeld/kilometergeld.html'),
  controller: Kilometergeld
});
