class EmployeeBenefits {
  constructor($translate, Notification) {
    Object.defineProperties(this, {
      $translate:   { value: $translate   },
      notification: { value: Notification }
    });
    this.promoCode = 'tempton2018';
    this.imageUrl = 'https://tempton.vorteile.net/';
  }

  openImageURL() {
    if (this.banner.url.startsWith("#")) window.location.href = this.banner.url;
    else return this.notification.confirm_p({
      title: 'note',
      desc: 'employee-benefits.popupDesc',
      cancelButton: 'cancel',
      button: 'yes'
    })
    .then((res) => {
      if (res === 2) window.open(this.banner.url || this.imageUrl, '_system');
    });
  }

  openBenefitProgram() {
    return this.notification.confirm_p({
      title: 'note',
      desc: 'employee-benefits.popupDesc',
      cancelButton: 'cancel',
      button: 'yes'
    })
    .then((res) => {
      if (res === 2) window.open(this.imageUrl, '_system');
    });
  }

  copyToClipboard() {
    var copyElement = document.createElement("textarea");
    copyElement.style.position = 'fixed';
    copyElement.style.opacity = '0';
    copyElement.textContent =  decodeURI(this.promoCode);
    var body = document.getElementsByTagName('body')[0];
    body.appendChild(copyElement);
    copyElement.select();
    document.execCommand('copy');
    body.removeChild(copyElement);

    this.$translate(['employee-benefits.copyToClipboard'])
    .then((t) => this.notification.message(t['employee-benefits.copyToClipboard'], 'short', 'top'));
  }

}

app.component('employeeBenefits', {
  template: require('scripts/components/employee-benefits/employee-benefits.html'),
  controller: EmployeeBenefits
});
